import React from 'react';
import { useSelector } from 'react-redux'
import {
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

const dollarUSLocale = Intl.NumberFormat('de-DE', {
  style:"currency",
  currency: "EUR"
});

//const DISTANCES = [70, 70, 82, 94, 110, 122, 134, 150]
const distance = (textLength, isMedium) => {
  let baseSize = 93
  let digitSize = 12
  let commaSize = 4
  if (isMedium) {
    baseSize = 82
    digitSize = 11
    commaSize = 4
  }
  if (textLength < 2) {
    return baseSize
  }
  const commaFactor = Math.floor((textLength - 1) / 3)
  return baseSize + (textLength - 2) * digitSize + commaFactor * commaSize
}

export function CustomSliderMoneyValue({ slider }) {
  const theme = useTheme()
  const rate = useSelector((state) => state.dashboard.rates.find((rate) => rate.sliderUuid === slider.uuid )) || { value: 0 }
  const budget = useSelector((state) => state.dashboard.budget)
  const dollarValueFormatted = dollarUSLocale.format(rate.value / 100.0);
  const showDollarValue = true
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  //const commasWidth = Math.floor(dollarValueWidth / 3) * 4
  //const dollarValueComponentWidth = 8 * dollarValueWidth + 33 + commasWidth;
  const dollarValueComponentWidth = distance(`${Math.floor(budget)}`.length, isSmallScreen);
  const style = showDollarValue ? {
    width: `${dollarValueComponentWidth}px`,
    alignItems: "center",
    justifyContent: "flex-end"
  } : {
    width: `${dollarValueComponentWidth}px`,
    textAlign: "center",
    margin: "0 auto",
    display: "table"
  }

  const deleteItem = () => {
    // deleteSlider({ uuid: slider.uuid })
  }

  return (
    <Grid item container sx={style}>
      { showDollarValue ? (
        <Typography component="span" sx={{ fontWeight: "bold" }}>
          {dollarValueFormatted}
        </Typography>
      ) : (
        <Typography component="span">
          <IconButton aria-label="delete" size="small" onClick={deleteItem}>
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      )}
    </Grid>
  )
}
