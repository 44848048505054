// We return true only when we can prove that user version
// is at least the indicated one, so unknown user version
// is going to fail this comparison (the function returns false then)
export const atLeastVersion = (targetVersion, userVersion) => {
  if (!userVersion) {
    return false
  }
  const userParts = userVersion.split(".")
  const targetParts = targetVersion.split(".")
  for (const index in [0,1,2]) {
    const userPart = parseInt(userParts[index])
    const targetPart = parseInt(targetParts[index])
    if (isNaN(userPart)) {
      return false
    }
    else if (userPart > targetPart) {
      return true
    }
    else if (userPart < targetPart) {
      return false
    }
  }
  return true
}

const currencyFormatter = Intl.NumberFormat('de-DE', {
  style:"currency",
  currency: "EUR"
});

export const centsAsCurrency = (cents) => {
  return currencyFormatter.format(cents / 100.0)
}