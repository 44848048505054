import React from 'react';
import {
  logOut
} from '../../lib/actions'
import { SimplyDefaultLayout } from '../simplyComponents/SimplyDefaultLayout'

export const DefaultLayout = ({ setAuthToken, authToken, account, children, sections, subtitle }) => {
  return (
    <SimplyDefaultLayout
      children={children}
      sections={sections}
      subtitle={subtitle}
      logOut={logOut}
      url="https://cordialcredit.org/"
      title="Cordial Credit"
      themeOverwrites={{
        palette: {
          primary: {
            main: '#ef6c00',
            dark: '#816131',
            light: '#ce9c73',
          },
          secondary: {
            main: '#039be5',
          },
          error: {
            main: '#d25f59',
          }
        }
      }}
    />
  )
}
