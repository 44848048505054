import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import EditSharpIcon from '@mui/icons-material/EditSharp'
import { SimplyIconButton } from '../simplyComponents/SimplyIconButton'
import { useSelector } from 'react-redux'
import { SimplyLink } from '../simplyComponents/SimplyLink';
import { CustomSlider } from '../shared/CustomSlider';

const SORTING = {
  date: { field: "userCreatedAt", direction: 1 },
  budget: { field: "value", direction: -1 },
  alphabetically: { field: "name", direction: 1 }
}

const attrBy = (record, sorting, sliders) => {
  if (sorting === "date") {
    return sliders[record.uuid][SORTING["date"].field]
  }
  if (sorting === "budget") {
    return sliders[record.uuid][SORTING["budget"].field]
  }
  // sorting === "alphabetically"
  return record[SORTING["alphabetically"].field]
}

const sortBy = (sorting, nonprofits, sliders) => {
  const newNonprofits = Array.from(nonprofits);
  const slidersByUuids = []
  sliders.forEach((slider) => {
    slidersByUuids[slider.nonprofitUuid] = slider
  })
  return newNonprofits.sort((a, b) => {
    const valA = attrBy(a, sorting, slidersByUuids)
    const valB = attrBy(b, sorting, slidersByUuids)
    if (valA < valB) {
      return -1 * SORTING[sorting].direction
    }
    if (valA > valB) {
      return 1 * SORTING[sorting].direction
    }
    return 0
  })
}

export function Nonprofits({ setSubtitle }) {
  const [sorting, setSorting] = useState("date")
  const [sortedNonprofits, setSortedNonprofits] = useState([])
  const budget = useSelector((state) => state.dashboard.budget)
  const nonprofits = useSelector((state) => state.dashboard.nonprofits)
  const proposals = useSelector((state) => state.dashboard.proposals)
  const sliders = useSelector((state) => state.dashboard.sliders)
  const slidersFetched = useSelector((state) => state.dashboard.fetched)
  const dollarUSLocale = Intl.NumberFormat('de-DE', {
    style:"currency",
    currency: "EUR"
  });
  useEffect(() => {
    const nonprofitsCount = nonprofits.length + proposals.length
    setSubtitle({ dashboard: `${nonprofitsCount} nonprofits` })
  }, [nonprofits.length, proposals.length])
  const resort = () => {
    setSortedNonprofits(sortBy(sorting, nonprofits, sliders))
  }
  useEffect(() => {
    if ((nonprofits.length > 0) && (sliders.length > 0) && (sorting !== "")) {
      resort()
    }
  }, [nonprofits, sliders, sorting])

  const handleSortingChange = (event) => {
    setSorting(event.target.value)
  }

  if (!slidersFetched) {
    return ""
  }

  return (
    <Grid container sx={{p: 0}}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{mb: 1, px: 2 }}>
          <Grid item>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-label">Sort by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={(sorting === "") ? "date" : sorting}
                label="Sort by"
                onChange={handleSortingChange}
              >
                <MenuItem value={"date"}>Date added</MenuItem>
                <MenuItem value={"budget"}>Budget</MenuItem>
                <MenuItem value={"alphabetically"}>Name</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            { budget ? (
              <Typography sx={{ float: "right", display: "flex", alignItems: "end", pb: "4px" }}>
                <Box
                  component="span"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  Community monthly budget:
                </Box>{'\u00A0'}
                <Box
                  component="span"
                  sx={{ display: { xs: "none", sm: "inline", md: "none" } }}
                >
                  Budget:{'\u00A0'}
                </Box>{'\u00A0'}
                <span style={{fontWeight: "bold"}}>
                  {dollarUSLocale.format(budget / 100.0)}
                </span>
              </Typography>
            ) : (
              <Typography sx={{ float: "right", display: "flex", alignItems: "end", pb: "4px" }}>
                <SimplyLink to="/budget">Set your monthly budget</SimplyLink>
              </Typography>
            )}
            <Typography sx={{ float: "right", display: "flex", alignItems: "end", pb: "4px", mr: "8px" }}>
              <SimplyLink to="/nonprofits/new">Add</SimplyLink>
            </Typography>
          </Grid>
        </Grid>

        { sortedNonprofits.map((nonprofit) => <CustomSlider
          key={nonprofit.uuid}
          nonprofit={nonprofit}
          resort={resort}
        />) }

      </Grid>
    </Grid>
  )
}
