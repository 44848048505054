import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  Box,
  Grid,
  useMediaQuery
} from '@mui/material';
import { PrettySlider } from './PrettySlider'
import { SimplyLink } from '../simplyComponents/SimplyLink';
import { CustomSliderMoneyValue } from './CustomSliderMoneyValue'

const DEFAULT_ICON = "org_default.png"

const marks = [
  {
    value: 0,
  },
  {
    value: 100,
  },
];

export function CustomSlider({ nonprofit, resort }) {
  const theme = useTheme()
  const darkMode = theme.palette.mode === "dark"
  const slider = useSelector((state) => state.dashboard.sliders.find((sl) => sl.nonprofitUuid === nonprofit.uuid)) || { value: 0 }
  const IsIconSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const setValue = (value) => {
    // setNewSliderValue({ uuid: slider.uuid, value })
  }

  const onSubmit = (value) => {
    // updateSlider({ uuid: slider.uuid, value })
  }

  useEffect(() => {
    resort()
  }, [slider.value])

  const sliderPosition = slider
    ? ((typeof(slider.newValue) === "undefined")
      ? slider.value
      : slider.newValue)
    : 0;

  const iconPath = nonprofit.iconPath || DEFAULT_ICON
  const nonprofitName = nonprofit.status === "approved" ? nonprofit.name : `${nonprofit.name} (${nonprofit.status})`

  const icon = (
    nonprofit.iconBackgroundColor ? (
      <Box
        component="img"
        sx={{
          width: IsIconSmall ? 50 : 60,
          mr: IsIconSmall ? 2 : 2,
          verticalAlign: "middle",
          backgroundColor: nonprofit.iconBackgroundColor,
          p: 1
        }}
        alt={nonprofitName}
        src={iconPath}
      />
    ) : (
      <Box
        component="img"
        sx={{
          width: IsIconSmall ? 50 : 60,
          mr: IsIconSmall ? 2 : 2,
          verticalAlign: "middle"
        }}
        alt={nonprofitName}
        src={iconPath}
      />
    )
  )

  const backbroundColorOnHover = darkMode ? "rgba(255,255,255,0.03)" : "rgba(0,0,0,0.03)"

  return (
    <Box sx={{ '&:hover': { backgroundColor: backbroundColorOnHover }, p: 2 }}>
      <Grid container wrap="nowrap">
        <Grid item sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {icon}
        </Grid>
        <Grid item xs>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs>
              <SimplyLink sx={{ textDecoration: "none", color: "inherit" }} to={`/nonprofits/${nonprofit.uuid}`}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }}
                  gutterBottom
                >
                  {nonprofitName}
                </Typography>
              </SimplyLink>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item container xs alignItems="center" sx={{ height: "40px"}}>
              <PrettySlider
                disabled
                aria-label="pretty slider"
                value={sliderPosition}
                onChange={(ev, newValue) => setValue(newValue)}
                onChangeCommitted={(ev, newValue) => onSubmit(newValue)}
                valueLabelDisplay="auto"
                marks={marks}
              />
            </Grid>
            <CustomSliderMoneyValue slider={slider} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
