import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Paper,
  Button,
  Grid,
  Typography
} from '@mui/material';

function createData(name, date, payment) {
  return { name, date, payment };
}

const rows = [
  createData('Mozilla Fundation', "In 2 days", 100.0),
  createData('Extinction Rebellion', "In 2 days", 1000.0),
  createData('World Food Programme', "In 2 days", 12.0),
  createData('UNICEF', "In 2 days", 1.0),
  createData('Amnesty International', "In 2 days", 0.32),
];

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function Transfer() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Mozilla Foundation 2
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {formatter.format(123.0)}
        </Typography>
      </Grid>
    </Grid>
  );
}
