import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { SimplyButton } from '../simplyComponents/SimplyButton'
import {
  Paper
} from '@mui/material';
import Moment from 'react-moment';
import { useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm';
import {
  fetchObligations,
  confirmTransfer
} from "../../lib/actions.js"
import {
  centsAsCurrency
} from "../../lib/utils.js"

const linkify = (inputText) => {
  if (!inputText) {
    return inputText;
  }
  const replacePattern = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  const replacedText = inputText.replace(replacePattern, '<a href="$1" target="_blank">$1</a>');
  return <span dangerouslySetInnerHTML={{ __html: replacedText}} />;
}

export function Transfers() {
  const confirm = useConfirm();
  const authToken = useSelector((state) => state.authToken.value)
  const obligations = useSelector((state) => state.obligations.items)
  useEffect(() => {
    if (authToken) {
      fetchObligations()
    }
  }, [authToken])
  const transferMadeClick = (obligation) => () => {
    confirm({ description: `Are you sure you want to confirm that you transfered ${centsAsCurrency(obligation.valueInCents)} ${obligation.currency} to ${obligation.nonprofit.name}?` })
      .then(() => { confirmTransfer({
        transfer: {
          nonprofitUuid: obligation.nonprofit.uuid,
          valueInCents: obligation.valueInCents,
          currency: obligation.currency
        }
      })})
      .catch(() => { /* ... */ });
  }

  return (
    <Grid container sx={{mb: 1, px: 2 }}>
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">To pay</TableCell>
                <TableCell align="right">Due date</TableCell>
                <TableCell align="right">Payment notes</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {obligations.map((obligation) => (
                <TableRow
                  key={obligation.nonprofit.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {obligation.nonprofit.name}
                  </TableCell>
                  <TableCell align="right">
                    {centsAsCurrency(obligation.valueInCents)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Moment fromNow>{obligation.dueDate}</Moment>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {linkify(obligation.nonprofit.paymentNotes)}
                  </TableCell>
                  <TableCell>
                    { (obligation.lastTransfers.length > 0) ? (
                      <div>
                        Last 3 transfers:
                        <ul>
                          {obligation.lastTransfers.map((transfer) => (
                            <li key={transfer.createdAt}>{centsAsCurrency(transfer.valueInCents)} {transfer.currency} <Moment fromNow>{transfer.createdAt}</Moment> by <span>{transfer.userName}</span></li>
                          ))}
                        </ul>
                      </div>
                    ) : ""}
                    <Button variant="outlined" size="small" onClick={transferMadeClick(obligation)}>
                      Transfer made
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
