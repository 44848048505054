import { backendUrl } from "./features"
import { callPost, callPut, callGet, callDelete, setupApi } from "./simplyLib/simplyApis"

setupApi(backendUrl())

export const callLogin = (params, onSuccess, onError) => {
  return callPost('auth/v1/login.json', params, onSuccess, onError);
}

export const callLogout = (authToken, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callDelete('auth/v1/logout.json', onSuccess, onError, headers)
}

export const callAccountGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('admin/v1/account.json', params, onSuccess, onError, headers);
}

export const callNonprofitsGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('admin/v1/nonprofits.json', params, onSuccess, onError, headers);
}

export const callNonprofitGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet(`admin/v1/nonprofits/${params.id}.json`, params, onSuccess, onError, headers);
}

export const callPaymentsGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('admin/v1/payments.json', params, onSuccess, onError, headers);
}

export const callDonationsGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('admin/v1/donations.json', params, onSuccess, onError, headers);
}

export const callObligationsGet = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callGet('admin/v1/obligations.json', params, onSuccess, onError, headers);
}

export const callTransfersPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost('admin/v1/transfers.json', params, onSuccess, onError, headers);
}

export const callNonprofitsPost = (authToken, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPost('admin/v1/nonprofits.json', params, onSuccess, onError, headers);
}

export const callNonprofitPut = (authToken, id, params, onSuccess, onError) => {
  const headers = {
    'Authorization': authToken
  }
  return callPut(`admin/v1/nonprofits/${id}.json`, params, onSuccess, onError, headers);
}
