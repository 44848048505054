import { configureStore } from '@reduxjs/toolkit'

const generateReducerHash = (slices) => {
  return slices.reduce((acc, obj) => {
    acc[obj.name] = obj.reducer
    return acc
  }, {})
}

const generateActionHash = (slices) => {
  return slices.reduce((acc, obj) => {
    acc[obj.name] = obj.actions
    return acc
  }, {})
}

export const simplyStore = (slices) => configureStore({
  reducer: generateReducerHash(slices),
})

export const simplyActions = (slices) => generateActionHash(slices)
