import React, { useEffect } from "react"
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SimplyButton } from '../simplyComponents/SimplyButton'
import {
  Paper
} from '@mui/material';
import Moment from 'react-moment';
import { useSelector } from 'react-redux'
import {
  fetchDonations,
  queryParams
} from "../../lib/actions.js"

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function Donations() {
  const authToken = useSelector((state) => state.authToken.value)
  const paymentId = queryParams()["paymentId"]
  const donations = useSelector((state) => state.donations.items)
  useEffect(() => {
    if (authToken) {
      fetchDonations(paymentId)
    }
  }, [authToken, paymentId])

  return (
    <Grid container spacing={3} sx={{mb: 1, px: 2 }}>
      <Grid item xs={12}>
        <Typography>
            Donations distribution for the payment {paymentId}.
        </Typography>
      </Grid>
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nonprofit</TableCell>
                <TableCell align="right">User</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {donations.map((donation) => (
                <TableRow
                  key={`${donation.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {donation.nonprofitName}
                  </TableCell>
                  <TableCell>{donation.userName}</TableCell>
                  <TableCell align="right">{formatter.format(donation.amount / 100.0)}</TableCell>

                  <TableCell align="right"><Moment fromNow>{donation.createdAt}</Moment></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
