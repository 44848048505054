import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import MoneyIcon from '@mui/icons-material/Money';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {
  retrieveTokenFromLocalStorage,
  fetchAccount,
  silenceAlert,
  fetchNonprofits
} from "./lib/actions.js"
import { Root } from "./Root";
import { Login } from "./components/Login/Login";
import { Nonprofits } from "./components/Nonprofits/Nonprofits";
import { Nonprofit } from "./components/Nonprofit/Nonprofit";
import { Payments } from "./components/Payments/Payments";
import { Donations } from "./components/Donations/Donations";
import { Transfers } from "./components/Transfers/Transfers";
import { Transfer } from "./components/Transfer/Transfer";
import { DefaultLayout } from "./components/Layouts/DefaultLayout";
import { AuthLayout } from "./components/Layouts/AuthLayout";
import { SimplyButton } from './components/simplyComponents/SimplyButton'
import {
  Snackbar,
  Alert,
  CssBaseline
} from "@mui/material"

const SECTIONS = {
  dashboard: {
    path: "/",
    title: "Nonprofits",
    menuIcon: <HomeIcon />
  },
  newNonprofit: {
    path: "/nonprofits/new",
    title: "Nonprofit",
    back: "/"
  },
  nonprofit: {
    path: "/nonprofits/:id",
    title: "Nonprofit",
    back: "/"
  },
  payments: {
    path: "/payments",
    title: "Payments",
    menuIcon: <VolunteerActivismIcon />
  },
  donations: {
    path: "/donations",
    title: "Donations",
    back: "/payments"
  },
  transfers: {
    path: "/obligations",
    title: "Obligations",
    menuIcon: <MoneyIcon />
  },
  transfer: {
    path: "/transfers/:id",
    title: "Obligation",
    back: "/transfers"
  },
  login: {
    path: "/login",
    title: "Login"
  },
  signup: {
    path: "/signup",
    title: "Signup"
  },
  confirmation: {
    path: "/confirmation",
    title: "Confirmation"
  }
}

export const App = () => {
  const notification = useSelector((state) => state.notifications.value)
  const authToken = useSelector((state) => state.authToken.value)
  useEffect(() => {
    retrieveTokenFromLocalStorage()
  }, [])
  const [subtitle, setSubtitle] = useState({})
  useEffect(() => {
    if (authToken) {
      fetchAccount()
      fetchNonprofits()
    }
  }, [authToken])

  const alertAction = (action) => {
    if (action === "goToDashboard") {
      return (
        <SimplyButton to="/" color="inherit" size="small" variant="outlined">
          Go back to dashboard
        </SimplyButton>
      )
    }
  }

  return (
    <BrowserRouter>
      < CssBaseline />
      { notification ? (
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={!!notification} autoHideDuration={6000} onClose={() => silenceAlert()}>
          <Alert
            onClose={() => silenceAlert()}
            variant="filled"
            severity={notification.severity}
            sx={{ width: '100%' }}
            action={
              notification.action ? alertAction(notification.action) : null
            }
          >
            {notification.text}
          </Alert>
        </Snackbar>
      ) : "" }
      <Routes>
        <Route path="/" element = {
          <Root
            authToken={authToken}
            sections={SECTIONS}
            accountVerification={false}
          />
        }>
          <Route index element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Nonprofits setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.newNonprofit.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Nonprofit setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.nonprofit.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Nonprofit setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.transfer.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Transfer setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.transfers.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Transfers setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.payments.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Payments setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.donations.path} element={
            <DefaultLayout subtitle={subtitle} sections={SECTIONS}>
              <Donations setSubtitle={setSubtitle} />
            </DefaultLayout>
          } />

          <Route path={SECTIONS.login.path} element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          } />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
