import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    value: false
  },
  reducers: {
    set: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload
    },
    reset: (state) => {
      state.value = false
    }
  },
})

const authTokenSlice = createSlice({
  name: 'authToken',
  initialState: {
    /*
      false - not set yet
      null/undefined - logged out
      string - logged in
    */
    value: false,
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    },
    reset: (state) => {
      state.value = null
    }
  },
})

const valueOf = (item) => (typeof item.newValue !== 'undefined') ? item.newValue : item.value
const valueSum = (acc, item) => acc + valueOf(item)

const calculateRates = (sliders, budget) => {
  const slidersSum = sliders.reduce(valueSum, 0)
  let split = sliders.map((slider) => {
    const value = valueOf(slider)
    return {
      sliderUuid: slider.uuid,
      value: budget * value / (1.0 * slidersSum)
    }
  })
  const actualSum = split.reduce((acc, item) => acc + item.value, 0)
  const error = budget - actualSum
  if ((error !== 0.0) && (split.length > 0)) {
    split[0].value = split[0].value + error
  }
  return split
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    fetched: false,
    nonprofits: [],
    proposals: [],
    sliders: [],
    budget: 0.0,
    rates: []
  },
  reducers: {
    set: (state, action) => {
      state.nonprofits = action.payload.nonprofits
      state.proposals = action.payload.proposals
      const sliders = action.payload.sliders
      const budget = action.payload.budget
      state.sliders = sliders
      state.budget = budget
      state.rates = calculateRates(sliders, budget)
      state.fetched = true
    },
    setNewValue: (state, action) => {
      const uuid = action.payload.uuid
      const value = action.payload.value
      const slider = state.sliders.find((slider) => slider.uuid === uuid)
      slider.newValue = value
      state.rates = calculateRates(state.sliders, state.budget)
    },
    updateBudget: (state, action) => {
      const budget = action.payload.budget
      state.budget = budget
      state.rates = calculateRates(state.sliders, budget)
    }
  },
})

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    value: {}
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload
    }
  }
})

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    items: []
  },
  reducers: {
    set: (state, action) => {
      state.items = action.payload
    }
  }
})

const donationsSlice = createSlice({
  name: 'donations',
  initialState: {
    items: []
  },
  reducers: {
    set: (state, action) => {
      state.items = action.payload
    }
  }
})

const obligationsSlice = createSlice({
  name: 'obligations',
  initialState: {
    items: []
  },
  reducers: {
    set: (state, action) => {
      state.items = action.payload
    }
  }
})

export const slices = [
  notificationSlice,
  authTokenSlice,
  dashboardSlice,
  accountSlice,
  paymentsSlice,
  donationsSlice,
  obligationsSlice
]
