import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Button,
  TextField,
  Input,
  Link
} from '@mui/material';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { fetchNonprofit } from "../../lib/actions.js"
import { createNonprofit, updateNonprofit } from "../../lib/actions";

export function Nonprofit() {
  const { id } = useParams();
  const [nonprofit, setNonprofit] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formName, setFormName] = useState("")
  const [formUrl, setFormUrl] = useState("")
  const [formIcon, setFormIcon] = useState("")
  const [formNotes, setFormNotes] = useState("")
  const [formColor, setFormColor] = useState("")

  const navigate = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true)
    const onDone = () => {
      setLoading(false)
      navigate("/")
    }
    const params = new FormData();
    params.append("name", formName)
    params.append("url", formUrl)
    params.append("icon", formIcon)
    params.append("paymentNotes", formNotes)
    params.append("backgroundColor", formColor)
    if (id) {
      updateNonprofit({ id, params, onDone })
    } else {
      createNonprofit({ params, onDone })
    }
  }

  useEffect(() => {
    if (id) {
      fetchNonprofit({ id, setNonprofit })
    }
  }, [id])

  useEffect(() => {
    if (nonprofit) {
      setFormName(nonprofit.name)
      setFormUrl(nonprofit.url)
      setFormNotes(nonprofit.notes || "")
      setFormColor(nonprofit.iconBackgroundColor || "")
      setFormIcon(nonprofit.iconPath)
    }
  }, [nonprofit, setFormName, setFormUrl, setFormIcon, setFormNotes, setFormColor])

  return (
    <Grid container spacing={3} sx={{mb: 1, px: 2 }}>
      <Grid item xs={12}>
        { id ? (
          nonprofit ? (
            <Typography sx={{ mb: 2 }}>
              Edit <Link target="_blank" href={nonprofit.url}>{nonprofit.name}</Link>
            </Typography>
          ) : ""
        ) : (
          <Typography sx={{ mb: 2 }}>
            Add a new nonprofit
          </Typography>
        )}
        <Paper sx={{ margin: "auto" , display: 'flex', flexDirection: 'column', boxShadow: "none" }} component="form" onSubmit={handleSubmit}>
          <TextField
            required
            id="name"
            name="name"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            label="Name of the nonprofit"
            sx={{ mb: 2 }}
          />
          <TextField
            id="url"
            name="url"
            label="Website of this nonprofit"
            value={formUrl}
            onChange={(e) => setFormUrl(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            id="backgroundColor"
            name="backgroundColor"
            label="Background Color (if needed)"
            value={formColor}
            onChange={(e) => setFormColor(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl style={{marginBottom: "16px"}}>
            <Input
              type="file"
              onChange={(e) => setFormIcon(e.target.files[0])}
              id="icon"
              name="icon"
            />
          </FormControl>
          <TextField
            id="paymentNotes"
            name="paymentNotes"
            label="Payment notes"
            value={formNotes}
            onChange={(e) => setFormNotes(e.target.value)}
            sx={{ mb: 2 }}
            multiline
            rows={4}
          />
          <Button type="submit" variant="contained" disabled={loading && false} sx={{ mt: 2 }}>{ id ? "Update" : "Add"}</Button>
        </Paper>
      </Grid>
    </Grid>
  )
}
