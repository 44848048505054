import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux'
import { useLocation, matchPath } from "react-router-dom";
import { styled, responsiveFontSizes, createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ConfirmProvider } from 'material-ui-confirm';
import { SimplyLeftMenu } from './SimplyLeftMenu';
import useMediaQuery from '@mui/material/useMediaQuery';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={props.url}>
        {props.title}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(8)
        }
      }),
    },
  }),
);

export const SimplyDefaultLayout = ({ children, sections, subtitle, url, title, logOut, themeOverwrites, transparentHeader }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => {
    const themeDefaults = {
      palette: {
        mode: prefersDarkMode ? "dark" : "light"
      },
      typography: {
        fontSize: 18,
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
          "Helvetica",
          "Arial",
          "sans-serif"
        ]
      }
    }
    const themeAttributes = Object.assign({}, themeDefaults, themeOverwrites)
    return responsiveFontSizes(createTheme(themeAttributes))
  }, [prefersDarkMode, themeOverwrites])
  const account = useSelector((state) => state.account.value)
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation()
  const [currentSectionKey, setCurrentSectionKey] = useState({})
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const onItemClick = () => {
    if (!bigScreen) {
      setOpen(false)
    }
  }

  useEffect(() => {
    const isPath = (template, path) => matchPath({ path: template, exact: true }, path)
    const sectionById = (id) => isPath(sections[id].path, location.pathname)
    const id = Object.keys(sections).find((id) => sectionById(id))
    setCurrentSectionKey(id)
  }, [location.pathname, sections])
  const toggleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    setOpen(bigScreen)
  }, [bigScreen])
  const menuId = 'primary-search-account-menu'
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const logOutClick = () => {
    handleMenuClose()
    logOut()
  }
  const currentSection = sections[currentSectionKey] || {}
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logOutClick}>Log Out</MenuItem>
    </Menu>
  );
  const appBarColor = transparentHeader ? "inherit" : undefined

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          {renderMenu}
          <AppBar position="absolute" open={open} color={appBarColor} sx={{ boxShadow: "none", opacity: 0.9 }}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
                pl: '16px!important'
              }}
            >
              <Grid container direction="row" wrap="nowrap">
                <Grid item sx={{ marginLeft: "0px" }}>
                  <Grid container direction="column" sx={{
                    width: "48px",
                    mr: 2,
                    [theme.breakpoints.down("sm")]: {
                      mr: 0
                    },
                    ...(open && { display: 'none' })
                  }}>
                    <Grid item sx={{pl: "6px"}}>
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                { (currentSection.path === "/") ? "" : (
                  <Grid item>
                    <Typography
                      color="inherit"
                      noWrap
                      sx={{
                        flexGrow: 1,
                        mr: 1,
                        ml: 0,
                        mt: "4px",
                        [theme.breakpoints.down("md")]: {
                          mt: "0px"
                        },
                      }}
                    >
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={currentSection.back || "/"}
                        sx={{ textDecoration: "none"}}
                      >
                        <ArrowBackIcon/>
                      </Link>
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Grid container direction="column">
                    <Grid item sx={{lineHeight: 1}}>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, fontWeight: "bold" }}
                      >
                        {currentSection.title || ""}
                      </Typography>
                    </Grid>
                    <Grid item sx={{lineHeight: 1, fontSize: 15}}>
                      {subtitle[currentSectionKey]}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              { account?.name ? (
                <Typography sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block"} }}>
                  Hi, {account.name}!
                </Typography>
              ) : "" }
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircleIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                px: [1],
                minHeight: "64px!important"
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginLeft: "0px",
                  ...(!open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Link color="inherit" component={RouterLink} to="/" sx={{ textDecoration: "none"}}>
                <Typography sx={{
                  lineHeight: 0.8,
                  fontSize: "18px!important",
                  ml: 2,
                  ...(!open && { display: 'none' })
                }}>
                  cordial<br/>
                  credit
                </Typography>
              </Link>
            </Toolbar>
            <List sx={{pt: 0}}><SimplyLeftMenu account={account} onClick={onItemClick} sections={sections}/></List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container disableGutters maxWidth="lg" sx={{ mt: 4, mb: 4}}>
              { children }
              <Copyright sx={{ pt: 4 }} url={url} title={title} />
            </Container>
          </Box>
        </Box>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
