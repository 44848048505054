import React, { useEffect } from "react"
import Table from '@mui/material/Table';
import Grid from '@mui/material/Grid';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SimplyButton } from '../simplyComponents/SimplyButton'
import {
  Paper
} from '@mui/material';
import Moment from 'react-moment';
import { SimplyLink } from '../simplyComponents/SimplyLink';
import { useSelector } from 'react-redux'
import { fetchPayments } from "../../lib/actions.js"

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function Payments() {
  const authToken = useSelector((state) => state.authToken.value)
  const payments = useSelector((state) => state.payments.items)
  useEffect(() => {
    if (authToken) {
      fetchPayments()
    }
  }, [authToken])

  return (
    <Grid container spacing={3} sx={{mb: 1, px: 2 }}>
      <Grid item xs={12}>
        <Typography>
            Payments from individual donors.
        </Typography>
      </Grid>
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Payment</TableCell>
                <TableCell align="right">Fee</TableCell>
                <TableCell align="right">Stripe Fee</TableCell>
                <TableCell align="right">CC Fee</TableCell>
                <TableCell align="right">To distribute</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment) => (
                <TableRow
                  key={`${payment.name}-${payment.createdAt}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <SimplyLink to={`/donations?paymentId=${payment.id}`}>
                      {payment.userName}
                    </SimplyLink>
                  </TableCell>
                  <TableCell align="right">{formatter.format(payment.amount / 100.0)}</TableCell>
                  <TableCell align="right">{formatter.format(payment.fee / 100.0)}</TableCell>
                  <TableCell align="right">{formatter.format(payment.stripeFee / 100.0)}</TableCell>
                  <TableCell align="right">{formatter.format(payment.ccFee / 100.0)}</TableCell>
                  <TableCell align="right">{formatter.format(payment.actualAmount / 100.0)}</TableCell>

                  <TableCell align="right"><Moment fromNow>{payment.createdAt}</Moment></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
