import {
  callLogin,
  callLogout,
  callAccountGet,
  callNonprofitGet,
  callNonprofitPut,
  callNonprofitsGet,
  callNonprofitsPost,
  callPaymentsGet,
  callDonationsGet,
  callObligationsGet,
  callTransfersPost
} from "./apis.js"
import { store, actions } from '../store'

const AUTH_TOKEN_KEY = "authToken"
const GENERAL_ERROR_MESSAGE = "Something went wrong. Please try again in a moment."

const defaultOnErrorWithMessage = (err, afterCallback) => {
  defaultOnError(err) || store.dispatch(actions.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE }))
  if (afterCallback) {
    afterCallback()
  }
}

const defaultOnError = (err) => {
  console.log("ERROR", err)
  return deleteAuthTokenIfUnauthorized(err)
}

const deleteAuthTokenIfUnauthorized = (err) => {
  if (err.response && (err.response.status === 401)) {
    store.dispatch(actions.notifications.reset())
    window.localStorage.setItem(AUTH_TOKEN_KEY, null)
    return true
  }
}

export const retrieveTokenFromLocalStorage = () => {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY)
  // Devise JWT returns "null" as string sometimes
  const actualToken = (token === "null") ? null : token
  store.dispatch(actions.authToken.set(actualToken))
}

export const silenceAlert = () => {
  store.dispatch(actions.notifications.reset())
}

export const queryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export const logIn = ({ params, onValidationError, onDone }) => {
  const onError = (err) => {
    onDone()
    if (err.response && (err.response.status === 401)) {
      onValidationError(err)
    } else {
      store.dispatch(actions.notifications.set({ severity: "error", text: GENERAL_ERROR_MESSAGE }))
      console.log("ERROR", err)
    }
  }
  const onSuccess = (response) => {
    onDone()
    const authToken = response.headers.authorization
    if (authToken) {
      window.localStorage.setItem(AUTH_TOKEN_KEY, authToken)
      store.dispatch(actions.authToken.set(authToken))
    }
  }
  callLogin(params, onSuccess, onError)
}

export const fetchAccount = () => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const onError = (err) => defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.account.set(result.account))
  }
  callAccountGet(authToken, {}, onSuccess, onError)
}

export const logOut = () => {
  const state = store.getState()
  const authToken = state.authToken.value
  const onSuccess = () => {
    window.localStorage.removeItem(AUTH_TOKEN_KEY)
    store.dispatch(actions.authToken.reset())
  }
  const onError = (err) => {
    console.log("ERROR", err)
  }
  callLogout(authToken, onSuccess, onError)
}

export const fetchNonprofits = () => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = {}
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.dashboard.set(result))
  }
  callNonprofitsGet(authToken, params, onSuccess, onError)
}

export const fetchNonprofit = ({ id, setNonprofit }) => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = { id }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result.nonprofit
    setNonprofit(result)
  }
  callNonprofitGet(authToken, params, onSuccess, onError)
}

export const createNonprofit = ({ params, onDone }) => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const onError = (err) =>  defaultOnErrorWithMessage(err, onDone)
  const onSuccess = (response) => {
    const result = response.data.result
    onDone()
    store.dispatch(actions.dashboard.set(result))
  }
  callNonprofitsPost(authToken, params, onSuccess, onError)
}

export const updateNonprofit = ({ id, params, onDone }) => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const onError = (err) =>  defaultOnErrorWithMessage(err, onDone)
  const onSuccess = (response) => {
    const result = response.data.result
    onDone()
    store.dispatch(actions.dashboard.set(result))
  }
  callNonprofitPut(authToken, id, params, onSuccess, onError)
}

export const fetchPayments = () => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = {}
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.payments.set(result.payments))
  }
  callPaymentsGet(authToken, params, onSuccess, onError)
}

export const fetchDonations = (paymentId) => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = { paymentId }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.donations.set(result.donations))
  }
  callDonationsGet(authToken, params, onSuccess, onError)
}

export const fetchObligations = () => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const params = {}
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.obligations.set(result.obligations))
  }
  callObligationsGet(authToken, params, onSuccess, onError)
}

export const confirmTransfer = (params) => {
  const state = store.getState()
  const authToken = state.authToken.value
  if (!authToken) {
    return
  }
  const onError = (err) =>  defaultOnErrorWithMessage(err)
  const onSuccess = (response) => {
    const result = response.data.result
    store.dispatch(actions.obligations.set(result.obligations))
  }
  callTransfersPost(authToken, params, onSuccess, onError)
}
